import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/alata";
import "@fontsource/asap";

import {
  SpellingErrorsModal,
  AboutTheOrgModal,
  InformationModalOpener,
} from "~/components/modals";

interface ContainsModals {
  modalFunctions: ((arg0: boolean) => void)[];
  modalRatings: number[];
}

export const Header = (): JSX.Element => {
  return (
    <div className="bg-white">
      <div className="flex flex-col md:flex-row flex-wrap md:justify-between md:ml-20 mt-2 md:mt-12 mb-6 text-3xl">
        <h1 className="text-safe-environment-indigo-900 mt-4 ml-4 float-right md:mt-0 lg:flex-wrap md:w-2/5 lg:w-1/3 lg:pr-12 xl:mr-0 xl:w-1/4 2xl:w-1/6">
          Foundation for a Safe Environment
        </h1>
        <a className="font-black mr-6 ml-6 flex content-end mt-4 justify-end md:mt-0" href="#" style={{ fontFamily: "Asap" }}>
          www.fsafeenviron.org
        </a>
      </div>
      <div className="bg-safe-environment-blue-800  flex justify-end">
        <Link to="/article/safe-environment" className="text-lg text-white py-4 md:px-12 xl:px-32 px-3">Home</Link>
        <Link className="text-lg text-white py-4" to="/article/safe-environment/about-us">About Us</Link>
        <Link className="text-lg text-white py-4 md:px-12 xl:px-32 px-4" to="/article/safe-environment/contact-us">Contact Us</Link>
      </div>
    </div>
  );
};

const Body = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="flex flex-col md:flex-row  md:ml-20 mx-10 text-black">
      <div className="md:flex-col text-base">
        <div className="flex-row mt-10 mr-4 mb-6 relative">
          <h2 className="text-3xl">About Us</h2>
          <p className="mt-6">The Foundation for a Safe Environment is staffed by scientists with a wide range of skills and expertise.</p>
          <InformationModalOpener
            className="absolute top-0 right-0"
            rating={modalRatings[1]}
            onClick={() => modalFunctions[1](true)}
          />
        </div>
        <div className="flex-row mt-14">
          <h2 className="text-3xl">Our History</h2>
          <p className="mt-6">The Foundation for a Safe Environment was founded in 1975, inspired by the creation of Earth Day in 1970. Over the years, more than 1,000 scientists from the United States and 37 other countries have participated in our programs. The foundation’s original focus on was on ecology; however today our efforts extend to a wide variety of other scientific and technological areas.</p>
        </div>
        <div className="flex-row mt-14 relative">
          <h2 className="text-3xl">President</h2>
          <p className="mt-6 mb-6">The current president of the Foundation for a Safe Environment is Dr. Harold R. Anderson. Dr. Anderson is the CEO of ESPS Inc., a firm that specializes in the development of environmentally friendly techniques for recycling and disposal of electronic equipment. Dr. Anderson holds degrees in electrical engineering and business administration. He has extensive teaching experience and has given presentations at scientific conferences around the world.</p>
          <InformationModalOpener
            className="absolute top-0 right-0"
            rating={modalRatings[0]}
            onClick={() => modalFunctions[0](true)}
          />
        </div>
      </div>
      <div className="md:flex-col mt-14 mx-12 md:ml-12 md:mr-4">
        <StaticImage
          alt="Students cell phones"
          className="mb-6"
          loading="eager"
          placeholder="blurred"
          src="../../../images/safe-environment-tower.jpg"
        />
      </div>
    </div>
  );
};


const SafeEnvironmentAboutUs = (): JSX.Element => {
  const [aboutTheOrgModalVisible, setAboutTheOrgModalVisible] = useState<boolean>(false);
  const [aboutTheOrgModalRating, setAboutTheOrgModalRating] = useState<number>(-1);
  const [spellingErrorsModalVisible, setSpellingErrorsModalVisible] = useState<boolean>(false);
  const [spellingErrorsModalRating, setSpellingErrorsModalRating] = useState<number>(-1);

  return (
    <div style={{ fontFamily: "Alata" }}>
      <Header />
      <Body modalFunctions={[setSpellingErrorsModalVisible, setAboutTheOrgModalVisible]} modalRatings={[spellingErrorsModalRating, aboutTheOrgModalRating]} />
      {aboutTheOrgModalVisible && (
        <AboutTheOrgModal
          modalRating={aboutTheOrgModalRating}
          modalVisible={aboutTheOrgModalVisible}
          setModalRating={setAboutTheOrgModalRating}
          setModalVisible={setAboutTheOrgModalVisible}
        />
      )}
      {spellingErrorsModalVisible && (
        <SpellingErrorsModal
          modalRating={spellingErrorsModalRating}
          modalVisible={spellingErrorsModalVisible}
          setModalRating={setSpellingErrorsModalRating}
          setModalVisible={setSpellingErrorsModalVisible}
        />
      )}
    </div>
  )
};

export default SafeEnvironmentAboutUs;
